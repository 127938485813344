import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, withKeys as _withKeys, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "icon-only",
                      icon: _ctx.closeOutline
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate('Shopify location')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          onKeyup: _cache[6] || (_cache[6] = _withKeys(($event: any) => (_ctx.type && _ctx.type === 'update' ? _ctx.updateMapping() : _ctx.saveMapping()), ["enter"])),
          onSubmit: _cache[7] || (_cache[7] = _withModifiers(() => {}, ["prevent"]))
        }, [
          _createVNode(_component_ion_list, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_list_header, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Facility details")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.translate("Facility ID")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_label, { slot: "end" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.currentFacility.facilityId), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, { lines: "none" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.translate("Facility name")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_label, { slot: "end" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.currentFacility.facilityName), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_list, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_list_header, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate('Shopify location')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, {
                onKeyup: _cache[2] || (_cache[2] = _withKeys(_withModifiers(() => {}, ["stop"]), ["enter"]))
              }, {
                default: _withCtx(() => [
                  (_ctx.type && _ctx.type === 'update')
                    ? (_openBlock(), _createBlock(_component_ion_label, { key: 0 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Shopify store")), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.type && _ctx.type === 'update')
                    ? (_openBlock(), _createBlock(_component_ion_label, {
                        key: 1,
                        slot: "end"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.shopifyFacilityMapping.shopId), 1)
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createBlock(_component_ion_select, {
                        key: 2,
                        label: _ctx.translate('Shopify store'),
                        interface: "popover",
                        placeholder: _ctx.translate('Select'),
                        modelValue: _ctx.shopId,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.shopId) = $event))
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shopifyShops, (shop) => {
                            return (_openBlock(), _createBlock(_component_ion_select_option, {
                              key: shop.shopId,
                              value: shop.shopId
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(shop.name ? shop.name : shop.shopId), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["label", "placeholder", "modelValue"]))
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_input, {
                    label: _ctx.translate('Location ID'),
                    placeholder: _ctx.translate('Add your location ID from Shopify'),
                    modelValue: _ctx.shopifyLocationId,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.shopifyLocationId) = $event))
                  }, null, 8, ["label", "placeholder", "modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_fab, {
            vertical: "bottom",
            horizontal: "end",
            slot: "fixed"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_fab_button, {
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.type && _ctx.type === 'update' ? _ctx.updateMapping() : _ctx.saveMapping())),
                onKeyup: _cache[5] || (_cache[5] = _withKeys(_withModifiers(() => {}, ["stop"]), ["enter"]))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.saveOutline }, null, 8, ["icon"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ], 32)
      ]),
      _: 1
    })
  ], 64))
}