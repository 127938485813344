import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withModifiers as _withModifiers, withKeys as _withKeys, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40ad6cfb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { slot: "label" }
const _hoisted_2 = { slot: "label" }
const _hoisted_3 = { slot: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item_divider = _resolveComponent("ion-item-divider")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "icon-only",
                      icon: _ctx.closeOutline
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("Address and contact details")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          onKeyup: _cache[12] || (_cache[12] = _withKeys(($event: any) => (_ctx.saveContact()), ["enter"]))
        }, [
          _createVNode(_component_ion_item_divider, { color: "light" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Address")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_input, {
                label: _ctx.translate('Shipping name'),
                "label-placement": "floating",
                modelValue: _ctx.address.toName,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.address.toName) = $event))
              }, null, 8, ["label", "modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_input, {
                "label-placement": "floating",
                modelValue: _ctx.address.address1,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.address.address1) = $event))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createTextVNode(_toDisplayString(_ctx.translate("Address line 1")) + " ", 1),
                    _createVNode(_component_ion_text, { color: "danger" }, {
                      default: _withCtx(() => [
                        _createTextVNode("*")
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_input, {
                label: _ctx.translate('Address line 2'),
                "label-placement": "floating",
                modelValue: _ctx.address.address2,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.address.address2) = $event))
              }, null, 8, ["label", "modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_input, {
                "label-placement": "floating",
                modelValue: _ctx.address.city,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.address.city) = $event))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createTextVNode(_toDisplayString(_ctx.translate("City")) + " ", 1),
                    _createVNode(_component_ion_text, { color: "danger" }, {
                      default: _withCtx(() => [
                        _createTextVNode("*")
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, {
            onKeyup: _cache[7] || (_cache[7] = _withKeys(_withModifiers(() => {}, ["stop"]), ["enter"]))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_select, {
                "label-placement": "floating",
                label: _ctx.translate('Country'),
                interface: "popover",
                placeholder: _ctx.translate('Select'),
                onIonChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updateState($event))),
                modelValue: _ctx.address.countryGeoId,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.address.countryGeoId) = $event))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countries, (country) => {
                    return (_openBlock(), _createBlock(_component_ion_select_option, {
                      key: country.geoId,
                      value: country.geoId
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(country.geoName), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["label", "placeholder", "modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, {
            onKeyup: _cache[9] || (_cache[9] = _withKeys(_withModifiers(() => {}, ["stop"]), ["enter"]))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_select, {
                "label-placement": "floating",
                label: _ctx.translate('State'),
                interface: "popover",
                disabled: !_ctx.address.countryGeoId,
                placeholder: _ctx.translate('Select'),
                modelValue: _ctx.address.stateProvinceGeoId,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.address.stateProvinceGeoId) = $event))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.states[_ctx.address.countryGeoId], (state) => {
                    return (_openBlock(), _createBlock(_component_ion_select_option, {
                      key: state.geoId,
                      value: state.geoId
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(state.wellKnownText && state.wellKnownText !== state.geoName ? `${state.geoName} (${state.wellKnownText})` : state.geoName), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["label", "disabled", "placeholder", "modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_input, {
                "label-placement": "floating",
                modelValue: _ctx.address.postalCode,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.address.postalCode) = $event))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createTextVNode(_toDisplayString(_ctx.translate("Zipcode")) + " ", 1),
                    _createVNode(_component_ion_text, { color: "danger" }, {
                      default: _withCtx(() => [
                        _createTextVNode("*")
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item_divider, { color: "light" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Contact details")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_input, {
                label: _ctx.translate('Contact number'),
                "label-placement": _ctx.telecomNumberValue?.countryCode ? 'stacked' : 'floating',
                modelValue: _ctx.telecomNumberValue.contactNumber,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.telecomNumberValue.contactNumber) = $event))
              }, {
                default: _withCtx(() => [
                  (_ctx.telecomNumberValue?.countryCode)
                    ? (_openBlock(), _createBlock(_component_ion_text, {
                        key: 0,
                        slot: "start"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.telecomNumberValue?.countryCode), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["label", "label-placement", "modelValue"])
            ]),
            _: 1
          })
        ], 32)
      ]),
      _: 1
    }),
    _createVNode(_component_ion_fab, {
      vertical: "bottom",
      horizontal: "end",
      slot: "fixed"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_fab_button, {
          onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.saveContact())),
          disabled: !_ctx.isAddressUpdated() && !_ctx.isTelecomNumberUpdated()
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, { icon: _ctx.saveOutline }, null, 8, ["icon"])
          ]),
          _: 1
        }, 8, ["disabled"])
      ]),
      _: 1
    })
  ], 64))
}