import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6099f8c3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { slot: "label" }
const _hoisted_2 = { slot: "label" }
const _hoisted_3 = { slot: "label" }
const _hoisted_4 = { class: "ion-text-center ion-margin" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_popover = _resolveComponent("ion-popover")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_back_button, {
                "default-href": "/tabs/find-facilities",
                slot: "start"
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Add Store Configuration")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("main", null, [
            _createVNode(_component_ion_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_header, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("Product Stores")), 1)
                      ]),
                      _: 1
                    }),
                    (_ctx.selectedProductStores.length)
                      ? (_openBlock(), _createBlock(_component_ion_button, {
                          key: 0,
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectProductStore())),
                          fill: "clear"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, {
                              icon: _ctx.addCircleOutline,
                              slot: "start"
                            }, null, 8, ["icon"]),
                            _createTextVNode(" " + _toDisplayString(_ctx.translate("Add")), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                (_ctx.selectedProductStores.length)
                  ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedProductStores, (store) => {
                          return (_openBlock(), _createBlock(_component_ion_item, {
                            key: store.productStoreId
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createElementVNode("h2", null, _toDisplayString(_ctx.getProductStore(store.productStoreId)?.storeName), 1)
                                ]),
                                _: 2
                              }, 1024),
                              (store.productStoreId === _ctx.primaryFacilityGroupId)
                                ? (_openBlock(), _createBlock(_component_ion_badge, { key: 0 }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.translate("primary store")), 1)
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true),
                              _createVNode(_component_ion_button, {
                                id: "product-store-actions-trigger",
                                slot: "end",
                                fill: "clear",
                                color: "medium"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, {
                                    slot: "icon-only",
                                    icon: _ctx.ellipsisVerticalOutline
                                  }, null, 8, ["icon"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_popover, {
                                trigger: "product-store-actions-trigger",
                                showBackdrop: "false",
                                dismissOnSelect: "true"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_content, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_list, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_list_header, null, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.getProductStore(store.productStoreId).storeName), 1)
                                            ]),
                                            _: 2
                                          }, 1024),
                                          _createVNode(_component_ion_item, {
                                            button: "",
                                            onClick: ($event: any) => (_ctx.updatePrimaryFacilityGroupId(store.productStoreId))
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.translate("Primary")) + " ", 1),
                                              _createVNode(_component_ion_icon, {
                                                slot: "end",
                                                color: store.productStoreId === _ctx.primaryFacilityGroupId ? 'warning' : '',
                                                icon: store.productStoreId === _ctx.primaryFacilityGroupId ? _ctx.star : _ctx.starOutline
                                              }, null, 8, ["color", "icon"])
                                            ]),
                                            _: 2
                                          }, 1032, ["onClick"]),
                                          _createVNode(_component_ion_item, {
                                            button: "",
                                            lines: "none",
                                            onClick: ($event: any) => (_ctx.removeProductStore(store.productStoreId))
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.translate("Unlink")) + " ", 1),
                                              _createVNode(_component_ion_icon, {
                                                slot: "end",
                                                icon: _ctx.removeCircleOutline
                                              }, null, 8, ["icon"])
                                            ]),
                                            _: 2
                                          }, 1032, ["onClick"])
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_ion_button, {
                      key: 1,
                      expand: "block",
                      fill: "outline",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectProductStore()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("Add")) + " ", 1),
                        _createVNode(_component_ion_icon, {
                          slot: "end",
                          icon: _ctx.addCircleOutline
                        }, null, 8, ["icon"])
                      ]),
                      _: 1
                    }))
              ]),
              _: 1
            }),
            _createVNode(_component_ion_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_header, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("Fulfillment Settings")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_list, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_toggle, {
                          modelValue: _ctx.fulfillmentSettings.FAC_GRP,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.fulfillmentSettings.FAC_GRP) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("Sell Inventory Online")), 1)
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_toggle, {
                          modelValue: _ctx.fulfillmentSettings.PICKUP,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.fulfillmentSettings.PICKUP) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("Allow pickup")), 1)
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_toggle, {
                          modelValue: _ctx.fulfillmentSettings.OMS_FULFILLMENT,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.fulfillmentSettings.OMS_FULFILLMENT) = $event)),
                          onIonChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.createLoginCreds = true))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("Uses native fulfillment app")), 1)
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    (_ctx.fulfillmentSettings.OMS_FULFILLMENT)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createVNode(_component_ion_item, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_toggle, {
                                modelValue: _ctx.createLoginCreds,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.createLoginCreds) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.translate("Create login credentials")), 1)
                                ]),
                                _: 1
                              }, 8, ["modelValue"])
                            ]),
                            _: 1
                          }),
                          (_ctx.createLoginCreds)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createVNode(_component_ion_item, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_input, {
                                      "label-placement": "floating",
                                      modelValue: _ctx.username,
                                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.username) = $event))
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_1, [
                                          _createTextVNode(_toDisplayString(_ctx.translate('Username')) + " ", 1),
                                          _createVNode(_component_ion_text, { color: "danger" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode("*")
                                            ]),
                                            _: 1
                                          })
                                        ])
                                      ]),
                                      _: 1
                                    }, 8, ["modelValue"])
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_item, { lines: "none" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_input, {
                                      "label-placement": "floating",
                                      modelValue: _ctx.password,
                                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.password) = $event)),
                                      ref: "password",
                                      onKeyup: _ctx.validatePassword,
                                      onIonBlur: _ctx.markPasswordTouched,
                                      type: "password",
                                      "helper-text": "translate('Password should be at least 5 characters long, it contains at least one number, one alphabet and one special character.')"
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_2, [
                                          _createTextVNode(_toDisplayString(_ctx.translate('Password')) + " ", 1),
                                          _createVNode(_component_ion_text, { color: "danger" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode("*")
                                            ]),
                                            _: 1
                                          })
                                        ])
                                      ]),
                                      _: 1
                                    }, 8, ["modelValue", "onKeyup", "onIonBlur"])
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_item, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_input, {
                                      "label-placement": "floating",
                                      modelValue: _ctx.emailAddress,
                                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.emailAddress) = $event))
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_3, [
                                          _createTextVNode(_toDisplayString(_ctx.translate('Reset password email')) + " ", 1),
                                          _createVNode(_component_ion_text, { color: "danger" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode("*")
                                            ]),
                                            _: 1
                                          })
                                        ])
                                      ]),
                                      _: 1
                                    }, 8, ["modelValue"])
                                  ]),
                                  _: 1
                                })
                              ], 64))
                            : _createCommentVNode("", true)
                        ], 64))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_ion_button, {
                onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.saveStoreConfig()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    slot: "start",
                    icon: _ctx.locationOutline
                  }, null, 8, ["icon"]),
                  _createTextVNode(" " + _toDisplayString(_ctx.translate("Save configurations")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_button, {
                onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.router.replace(`/facility-details/${_ctx.facilityId}`))),
                color: "medium",
                fill: "clear"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Configure settings later")), 1)
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}